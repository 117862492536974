import React from "react";
import StoryPage from "../../components/story-page";

const ThisPage = ({ location }) => {
  const imgUrl =
    "https://cdn.shopify.com/s/files/1/0261/5885/files/Jordan_600x600.jpg?v=1601946338";
  const fullName = "Jordan Neustaeter";
  const zoomUrl = ""; // https://us02web.zoom.us/j/85271114852
  const zoomPhoneNumber = ""; // (587) 328-1099
  const zoomMeetingId = ""; // 852 7111 4852
  const birthDate = "January 8, 1989";
  const deathDate = "September 29, 2020";
  const memorialDate = ""; // Saturday October 10, 2020
  const memorialTime = ""; // 2:00 PM MST
  const inviteText = "";
  const obituary = `It is with deepest sorrow, we announce the passing of Jordan Neustaeter, beloved son, brother, grandson, uncle, family member and friend on September 29, 2020 at the age of 31 years.
  \nCherished forever by his parents, Shannon and Eldon Neustaeter and Rochelle Stirk; his sisters, Erin McDermott (Nick Van Mil) and Lyndsay McDermott; grandparents, Nettie and Dave Neustaeter and Helen Mitchem and Les Thedroff; his loving Aunts and Uncles, Cousins and many, many great friends.
  \nThough born and raised in Calgary, Jordan’s free spirit, love of nature and zest for life led him to many places around the world. From Qatar to Costa Rica, BC to North Carolina, he easily made lifelong friends wherever he went. One of Jordan's proudest moment was becoming Godfather to Ryan Oliu.
  \nHis pockets always filled with ‘treasures’, Jordan’s childhood was filled with happy memories whether it was weekends at Bergen playing at the springs with his sisters and cousins, Angelea and Laura, or proudly sharing his latest duct tape creations.
  \nTech guided his career and his lifelong passion for gaming allowed him to forge long-lasting relationships around the world.
  \nThose who knew Jordan, even just a little, lost a shining light intheir lives.`;
  const storiesRecordUrl = "https://stories.afterword.com/f0d31s0s6";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/r2f1di9ta6l1ltk0m1uka0c1vlczvew1un303yoc";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
    >
      <p>
        Memorial contributions can be made to a cause very close to his
        heart:&nbsp;
        <a
          href="https://pancreaticcancercanada.ca/honour-someone-special/"
          target="_blank"
          title="Donate to Canadian Pancreatic Research in Honour of Jordan"
          rel="noopener noreferrer"
          aria-describedby="a11y-new-window-external-message"
        >
          Canadian Pancreatic Research
        </a>
        &nbsp;or (416) 548-8077. We leave you with&nbsp;one of his photos:
      </p>
      <img
        src="https://cdn.shopify.com/s/files/1/0261/5885/files/119083347_1098306180668829_4141766494833787565_n_1_600x600.jpg?v=1602043836"
        sx={{ maxWidth: "400px", width: "100%" }}
        alt="Jordan Neustaeter's snapshot of a sunset"
      />
    </StoryPage>
  );
};

export default ThisPage;
